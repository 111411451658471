import React, { MutableRefObject, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SizeMe } from 'react-sizeme'

import { Options, Plot } from '@antv/g2plot'
import { TimeDimensionGranularity } from '@cubejs-client/core'

import { Report, ReportSetting, Target } from '@cozero/models'
import { ReportType } from '@cozero/models'

import TargetMixed from '@/organisms/TargetMixedGraph'

import { GraphTable } from '@/molecules/GraphTable/GraphTable'

import Bar from '@/atoms/G2Bar'
import FacetStackedBar from '@/atoms/G2FacetStackedBar'
import Line from '@/atoms/G2Line'
import Pie from '@/atoms/G2Pie'
import Sankey from '@/atoms/G2Sankey'
import Sunburst from '@/atoms/G2Sunburst'
import MapChart from '@/atoms/MapChart'
import Tag from '@/atoms/Tag'
import Tooltip from '@/atoms/Tooltip'

import { reportKeyToColorFunction } from '@/styles/graphs'

import classes from './ChartContainer.module.less'

type ReportGranularity = 'month' | 'quarter' | 'year' | undefined

const isReportGranularity = (
  granularity: TimeDimensionGranularity | undefined,
): granularity is ReportGranularity => {
  return ['month', 'quarter', 'year', undefined].includes(granularity)
}

interface Props {
  height?: number | null
  report: Report
  chartRef?: MutableRefObject<Plot<Options> | null>
  svgChartRef?: MutableRefObject<Plot<Options> | null>
  secondaryChartRef?: MutableRefObject<Plot<Options> | null>
  secondarySvgChartRef?: MutableRefObject<Plot<Options> | null>
  view?: string
  showColumns?: boolean
  showFilters?: boolean
  reportSetting?: ReportSetting
  showLegend?: boolean
  switchAxis?: boolean
  targets?: Target[]
}

export const ChartContainer = ({
  height,
  report,
  chartRef,
  svgChartRef,
  secondaryChartRef,
  secondarySvgChartRef,
  showColumns = false,
  showLegend = false,
  switchAxis = false,
  view,
  reportSetting,
  targets,
}: Props): ReactElement | null => {
  const { t } = useTranslation('common')
  const [size, setSize] = useState<{ width: number | null; height: number | null }>({
    width: null,
    height: null,
  })

  useEffect(() => {
    if (chartRef && chartRef.current && size?.width && height && view === 'primary') {
      chartRef.current.changeSize(size.width, height)
    } else if (chartRef && chartRef.current && size?.width && size?.height && view === 'primary') {
      chartRef.current.changeSize(size.width, size.height)
    }
  }, [size, height])

  if (!report) {
    return null
  }

  const fallbackTimeGranularity = isReportGranularity(
    report.cubeJSQuery?.timeDimensions?.[0]?.granularity,
  )
    ? report.cubeJSQuery?.timeDimensions?.[0]?.granularity
    : undefined

  return (
    <>
      {report?.type === ReportType.FORECAST && (
        <div className={classes.beta}>
          <Tooltip title={t('beta.tooltip')}>
            <Tag>{t('beta.title')}</Tag>
          </Tooltip>
        </div>
      )}
      {(report.type === ReportType.TABLE || view === 'table') &&
        report.data &&
        report.data.tablePivot?.length > 0 && (
          <GraphTable showColumns={showColumns} report={report} />
        )}

      <SizeMe key={report.id} monitorWidth monitorHeight refreshMode="debounce" refreshRate={32}>
        {({ size }) => {
          setSize(size)
          return (
            <>
              {report.type === ReportType.TARGETS_MIXED &&
                targets &&
                report.data &&
                report?.data?.series.length > 0 && (
                  <TargetMixed targets={targets} report={report} />
                )}
              {((report.type === ReportType.LINE && view === 'primary') || view === 'menu') &&
                report.type === ReportType.LINE &&
                report.data &&
                report?.data?.series?.length > 0 && (
                  <Line
                    height={height ?? size.height}
                    dimensionType={reportSetting?.dimension ?? report.cubeJSQuery.dimensions?.[0]}
                    report={report}
                    keys={report.data.seriesNames}
                    visible={view === 'primary'}
                    chartRef={report.type === ReportType.LINE ? chartRef : undefined}
                    svgChartRef={report.type === ReportType.LINE ? svgChartRef : undefined}
                    chartData={report.data.series}
                    measure={reportSetting?.measure ?? report.cubeJSQuery?.measures?.[0]}
                    timeGranularity={reportSetting?.timeGranularity ?? fallbackTimeGranularity}
                    showLegend={showLegend}
                    disableSlider={report.disableSlider || false}
                  />
                )}
              {((report.type.endsWith('bar') && view === 'primary') ||
                (view === 'bar' && !report.disableAlternativeGraph) ||
                view === 'menu') &&
                report.data &&
                report.data.seriesNames?.length > 0 && (
                  <Bar
                    dimensionType={reportSetting?.dimension ?? report.cubeJSQuery.dimensions?.[0]}
                    chartRef={report.type.endsWith('bar') ? chartRef : secondaryChartRef}
                    svgChartRef={report.type.endsWith('bar') ? svgChartRef : secondarySvgChartRef}
                    height={height ?? size.height}
                    chartData={report.data.chartPivot}
                    isStack={report.type === ReportType.STACKED_BAR}
                    keys={report.data.seriesNames}
                    visible={(report.type.endsWith('bar') && view === 'primary') || view === 'bar'}
                    disableSlider={report.disableSlider || false}
                    showLegend={showLegend}
                    switchAxis={switchAxis}
                    timeGranularity={reportSetting?.timeGranularity ?? fallbackTimeGranularity}
                    colorFunction={reportKeyToColorFunction.get(report.key)}
                  />
                )}
              {((report.type === ReportType.PIE && view === 'primary') ||
                (view === 'pie' && !report.disableAlternativeGraph) ||
                view === 'menu') &&
                report.data &&
                report.data.seriesNames?.length > 0 && (
                  <Pie
                    height={height ?? size.height}
                    dimensionType={reportSetting?.dimension ?? report.cubeJSQuery.dimensions?.[0]}
                    chartRef={report.type === ReportType.PIE ? chartRef : secondaryChartRef}
                    svgChartRef={
                      report.type === ReportType.PIE ? svgChartRef : secondarySvgChartRef
                    }
                    chartData={report.data.chartPivot}
                    valueKey={report.data.seriesNames[0]?.key}
                    visible={
                      (report.type === ReportType.PIE && view === 'primary') || view === 'pie'
                    }
                    showLegend={showLegend}
                  />
                )}
              {report.type === ReportType.SUNBURST &&
                (view === 'primary' || view === 'menu') &&
                report.data &&
                report.data.seriesNames?.length > 0 && (
                  <Sunburst
                    height={height ?? size.height}
                    dimensionType={reportSetting?.dimension ?? report.cubeJSQuery.dimensions?.[0]}
                    chartRef={report.type === ReportType.SUNBURST ? chartRef : secondaryChartRef}
                    svgChartRef={
                      report.type === ReportType.SUNBURST ? svgChartRef : secondarySvgChartRef
                    }
                    chartData={report.data.chartPivot}
                    valueKey={report.data.seriesNames[0]?.key}
                    visible={report.type === ReportType.SUNBURST && view === 'primary'}
                  />
                )}
              {report.type === ReportType.SANKEY &&
                (view === 'primary' || view === 'menu') &&
                report.data &&
                report.data.seriesNames?.length > 0 && (
                  <Sankey
                    height={height ?? size.height}
                    dimensionType={reportSetting?.dimension ?? report.cubeJSQuery.dimensions?.[0]}
                    chartRef={report.type === ReportType.SANKEY ? chartRef : secondaryChartRef}
                    svgChartRef={
                      report.type === ReportType.SANKEY ? svgChartRef : secondarySvgChartRef
                    }
                    chartData={report.data.chartPivot}
                    valueKey={report.data.seriesNames[0]?.key}
                    visible={report.type === ReportType.SANKEY && view === 'primary'}
                  />
                )}
              {report.type === ReportType.FACET_STACKED_BAR &&
                (view === 'primary' || view === 'menu') &&
                report.data &&
                report.data.seriesNames?.length > 0 && (
                  <FacetStackedBar
                    height={height ?? size.height}
                    dimensionType={reportSetting?.dimension ?? report.cubeJSQuery.dimensions?.[0]}
                    chartData={report.data.chartPivot}
                    keys={report.data.seriesNames}
                    width={size.width}
                    visible={report.type === ReportType.FACET_STACKED_BAR && view === 'primary'}
                    switchAxis={switchAxis}
                    showLegend={showLegend}
                  />
                )}
              {report.type === ReportType.FORECAST &&
                report.data &&
                report.data.series.length > 0 && (
                  <Line
                    height={height ?? size.height}
                    report={report}
                    keys={report.data.seriesNames}
                    chartData={report.data.series.map((forecastDatum) => ({
                      title: forecastDatum.title as string,
                      shortTitle: forecastDatum.title as string,
                      key: forecastDatum.key as string,
                      series: forecastDatum.series.map((datum) => ({
                        x: datum.x as string,
                        value: datum.value as number,
                      })),
                    }))}
                    timeGranularity="year"
                    visible
                    showLegend={false}
                    disableSlider
                  />
                )}
              {report.type === ReportType.MAP && report.data && report.data.series.length > 0 && (
                <MapChart report={report} />
              )}
            </>
          )
        }}
      </SizeMe>
    </>
  )
}
